@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.App {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

#contactMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}

#contactMain h1 {
    color: #DBB826;
}

form {
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

form input {
    padding: 15px;
    border: none;
    color: #FFF;
    border-radius: 5px;
    background-color: black;
    margin-top: 20px;
}

fieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    margin: 64px 0px 0px 0px;
    padding: 0;
    min-inline-size: auto;
    border: 0;
}

fieldset legend h2 {
    font-size: 24px;
}

fieldset form legend span {
    font-size: 14px;
    font-weight: normal;
    color: #DBB826;
}

fieldset .inputboxes {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

fieldset .field-group1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

fieldset .field-group {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

fieldset .field {
    display: flex;
    width: 45%;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
}

fieldset input {
    width: 100%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;
}

fieldset .selects {

    width: 105%;

}

fieldset .selects select {

    width: 110%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;

}

fieldset .selects select option {
    color: #DBB826;
}

fieldset .messageInput {
    width: 60%;
    font-family: 'Montserrat', sans-serif;
}

fieldset #message {
    height: 150px;
    width: 103%;
    background-color: #000;
    border-radius: 5px;
    color: #DBB826;
    padding: 15px;
}

.whatsappContact {
    display: flex;
    align-items: center;
    margin: 80px 0px;
}

.whatsappContact img {
    height: 45px;
    padding-left: 20px;
}

.whatsappContact a {
    cursor: pointer;
}

.sendButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 10px;
    margin-top: 25px;
    width: 130px;
    height: 40px;
}

.sendButton a {
    font-size: 13pt;
    color: #DBB826;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    form {
        width: 90vw;
    }
    fieldset {
        align-items: center;
    }
    fieldset .inputboxes {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    fieldset .field-group {
        flex-direction: column;
        align-items: flex-start;
    }
    fieldset .field-group {
        width: 95%;
    }
    fieldset .field-group1 {
        flex-direction: column;
        align-items: flex-start;
    }
    fieldset .field-group1 {
        width: 95%;
    }
    fieldset .field {
        width: 88%;
    }
    fieldset input {
        width: 95%;
    }
    fieldset .selects {
        width: 100%;
    }
    fieldset .messageInput {
        display: flex;
        align-items: center;
        width: 88%;
    }
}

@media only screen and (max-width: 600px) {

    fieldset .selects select {

        width: 112%;

    }

}