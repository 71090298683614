@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#Blog {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: black; */
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;

}

#mainBlog {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    margin-bottom: 10vh;

}

#mainBlog h1 {

    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;

}

#mainBlog h3 {

    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;

}

.postDiv {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Montserrat', 'sans-serif';
    margin-top: 10vh;
    /* background-color: rebeccapurple; */

}

.postDiv img{

    max-width: 40%;
    border-radius: 10px;

}

.postDiv h3 {

    padding: 15px;
    color: white;
    font-size: 22pt;
    position: relative;
    background-color: #DBB826;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 25px;
    border-top-left-radius:     25px;
    border-top-right-radius:    25px;

}

.postDiv h5{

    font-size: 13pt;
    max-width: 70%;

}

.postDiv p{

    width: 80%;
    color: black;
    text-align: justify;
    text-indent: 2em;
    white-space: pre-line;

}

.postAuthor {

    width: 80%;
    text-align: right;

}

.postAuthor span{

    color: white;
    padding: 5px;
    font-size: 10pt;
    border-radius: 5px;
    background-color: #DBB826;

}

.paragraphPost {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;

}

.paragraphPost p {

    width: 100%;
    text-align: justify;

}

.caminhoAviao{

    border-radius: 150px;
    width: 100%;
    height: 90%;
    animation: anticlockwise 8s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;

}

.caminhoAviao img {

    width: 30px;
    position: absolute;
    top: 0px;

}

.warning {

    position: absolute;
    top: 50%;

}

.warning p {

    font-family: 'Montserrat', sans-serif;
    font-size: 15pt;
    color: #DBB826;

}

.testeee {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 300px;
    height: 300px;

}


.warning p::after {

    animation: testt 5s infinite;
    content: '';

}

.sendToLinkedin {

    width: 100vw;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
    text-align: center;

}

.sendToLinkedin p {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;

}

.sendToLinkedin a {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;
    font-weight:bold;
    cursor: pointer;
    text-decoration: none;

}

@keyframes anticlockwise {

    0% {

        transform: rotate(-360deg);

    }
    100% {

        transform: rotate(0deg);

    }

}

@keyframes testt {

    0% {

        content: '.';

    }
    50% {

        content: '..';

    }
    75% {

        content: '...';

    }
    100% {

        content: '';

    }

}

@media screen and (max-width: 768px) {

    #mainBlog h1 {

        font-size: 14pt;

    }

    .postDiv h3 {

        width: 60%;
        padding: 10px;
        font-size: 18pt;
        text-align: center;

    }

    .postDiv h5 {

        font-size: 11pt;
        text-indent: 2em;
        text-align: justify;

    }
    
    .postDiv img{

    max-width: 80%;

}
    
}
