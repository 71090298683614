footer {

    width: 100vw;
    overflow-y: hidden;
    color: #FFF;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;

}

.copyright {

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;

}

.copyright p {

    font-size: 9pt

}

.copyright img {

    height: 80px;

}

.address {

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    max-height: 20vh;
    margin-bottom: 15px;
    text-align: center;

}

.address ul {

    display: flex;
    list-style: none;

}

.address li {

    padding: 5px;

}

.address li a {

    cursor: pointer;
    text-decoration: none;
    color: #FFF;

}

.socialMedias {

    height: 20vh;
    display: flex;
    align-items: flex-end;
    padding-right: 25px;

}

.socialMedias img {

    height: 38px;
    padding: 0px 4px;

}

.socialMedias a {

    cursor: pointer;

}

@media screen and (max-width: 768px) {

    footer {

        flex-direction: column;
        padding-bottom: 0;


    }

    .copyright {

        width: 90vw;
        height: auto;
        align-items: center;
        margin: 0px;

    }

    .copyright img {

        height: 110px;

    }

    .address{

        width: 90vw;
        height: auto;
        text-align: justify;
        margin: 0px;
        /* max-height: none; */

    }

    .address ul {

        width: 90vw;
        justify-content: space-between;
        padding: 0;

    }

    .address li {

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9pt;

    }

    .address p {

        font-size: 9pt;

    }

    .socialMedias {

        width: 100vw;
        height: 10vh;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: 50px;

    }

}