@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {

    scroll-behavior: smooth;

}

:root {

    overflow-x: hidden;

}

::-webkit-scrollbar {

    background: #FFF;

}

::-webkit-scrollbar-thumb { 

    background-color: #000;
    
}

.Home {

    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

#mainHome {

    width: 100vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-image: url("../../imgs/backComp.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */

}

#mainHome img {

    width: 1000px;

}

#presentationDiv {

    height: 100%;
    width: 100%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    
    
}

#presentationDiv img {

    height: 400px;
    width: 400px;
    margin-left: 10vw;

}

.phraseIntro {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;

}

.phraseIntro h1 {

    font-size: 25pt;
    color: #DBB826;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    margin-top: 1px;
    font-family: 'Montserrat', sans-serif;

}

.descIntro {

    width: 100%;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;

}

.waveImage {

    height: 100%;
    width: 300px;
}

#services {

    height: 90vh;
    width: 100vw;
    margin-top: 30vh;
    margin-bottom: 30vh;

}

#services h1 {

    font-size: 20pt;
    font-family: 'Montserrat', sans-serif;

}

.flexCol {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.sectionHome{

    margin-top: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.matrixServices {

    width: 95%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.rowMatrixServices {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boxServices {

    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

}

.boxServices a {

    cursor: pointer;

}

.boxServices img {

    height: 220px;
    transition: all 1s linear;
    margin-bottom: 20px;

}

.servicesBack {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 1s linear;

}

.servicesBack a {

    transition: all 2s linear;
    background-color: #DBB826;
    border-radius: 3px;
    padding: 5px;
    margin: 3px;
    opacity: 1;
    text-decoration: none;

}

.servicesBack a:hover {

    transition: all .2s linear;
    box-shadow: 0px 2px 8px #dbb726d2;

}

.servicesBack b {

    transition: all 2s linear;
    background-color: #DBB826;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin: 3px;
    opacity: 1;

}

.marginTopServicesBack {

    margin-top: 10px;

}

@keyframes teste {

    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-10px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }

}

@keyframes jumpAndDeformation {

    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-3px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }


}

#ourProjects {

    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 250px;

}

#ourProjects h1 {

    font-size: 24pt;

}

.slideProjects {

    width: 100vw;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.imgProjects {

    height: 500px;
    width: auto;
    border-radius: 10px;

}

.slide {

    opacity: 0;
    transition-duration: 1s ease;

}
  
.slide.active {

    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);

}

#feedbacks {

    width: 100vw;

}

.aboutUs {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15vh;
    margin-bottom: 30vh;
    /* background-color: #DBB826; */

}

.whyUs {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    text-indent: 2em;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 10px;

}

.parceiros {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 10px;
    width: 80%;
    margin-top: 30px;
}

.parceirosLogos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.parceirosLogos a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.parceirosLogos img {
    width: 100%;
    height: 50%;
}

.accountants {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15vh;

}

.counter {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}

.counter img {

    width: 100px;
    height: 100px;
    margin-bottom: 15px;

}

.counter strong {

    color: #DBB826;
    font-size: 20pt;

}


@media screen and (max-width: 768px) {

    #mainHome {

        flex-direction: column;
        height: auto;

    }

    #mainHome h1 {

        font-size: 18pt;

    }

    #presentationDiv{

        flex-direction: column;
        justify-content: center;
        text-align: justify;
        padding: 0px 30px;

    }

    #presentationDiv img{

        margin: 0;

    }

    #services {

        height: auto;
        margin-top: 15vh;
        margin-bottom: 0;

    }

    .descIntro {

        width: 90%;

    }

    /* .descIntro p {

        font-size: 14px;

    } */

    .rowMatrixServices {

        flex-direction: column;

    }

    .boxServices {

        margin-top: 20px;

    }

    .marginTop {

        margin-top: 80px;

    }

    #ourProjects {

        height: 70vh;
        margin-top: 100px;

    }

    .imgProjects {

        height: 300px;
        width: 90vw;

    }

    .accountants {

        flex-direction: column;

    }

    .counter {

        margin: 15px 0px;

    }

    .aboutUs {

        margin-top: 8vh;
        margin-bottom: 8vh;

    }
    .accountants {

        margin-top: 10vh;

    }

    .parceirosLogos {
        flex-direction: column;
    }

}

@media only screen and (max-width: 645px) {

    .descIntro {

        width: 75%;

    }

    .descIntro p {

      font-size: 10pt;

    }

    #services h1 {

        font-size: 17pt;

    }

    .whyUs h3 {

        font-size: 15pt;
        text-align: center;

    }

}

.whatsappButton {

    position: fixed;
    z-index: 9;
    bottom: 20px;
    left: 25px;

}

.whatsappButton img {

    width: 80px;

}

header {

    height: 10vh;
    width: 100vw;
    background-color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

}

.logo {

    padding-left: 20px;
    display: flex;
    align-items: center;

}

.logo img {
    height: 7vh;
}

.logo a {
    cursor: pointer;
}

.menu {

    width: 50vw;
    display: flex;
    flex-direction: row;

}

.menu ul {

    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.menu li {

    list-style: none;
    color: #DBB826;
    padding: 5px;
    font-size: 13pt;
    cursor: pointer;

}

.menu a {

    text-decoration: none;
    color: #FFF;

}

.menu a:hover {

    color: #DBB826;

}

.menu-mobile {

    display: none;

}

.sandwich {

    display: none;

}

.sandwich input {

    opacity: 0;

}


@media screen and (max-width: 768px) {

    .menu {
        display: none;
    }

    .sandwich {

        display: block;
        display: flex;
        align-items: center;
        padding-right: 20px;

    }

    .sandwich label {

        cursor: pointer;
        position: relative;
        display: block;
        height: 5px;
        width: 50px;

    }

    .sandwich span {

        position: absolute;
        height: 3px;
        width: 30px;
        background: #dbb826;
        transition: .5s ease-in-out;

    }

    .sandwich span:nth-child(1) {

        position: absolute;
        top: -8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    .sandwich span:nth-child(3) {

        position: absolute;
        top: 8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    #checkbox:checked + label span:nth-child(1) {

        transform: rotate(-225deg);
        top: 0px;

    }

    #checkbox:checked + label span:nth-child(2) {

        opacity: 0;

    }

    #checkbox:checked + label span:nth-child(3) {

        transform: rotate(225deg);
        top: 0px;

    }

    .menu-mobile {

        width: 100vw;
        height: 60px;
        display: none;
        align-items: center;
        justify-content: center;
        background-color: #000;

    }

    .menu-mobile ul {

        margin: 0;
        padding: 0;
        width: 90%;
        display: flex;
        justify-content: space-between;
        list-style: none;
        font-size: 9pt;
        color: #FFF;

    }

    .menu-mobile li {

        cursor: pointer;
        color: #FFF;

    }

    .menu-mobile a {

        text-decoration: none;
        color: #FFF;

    }

    .menu-mobile a:hover {

        color: #DBB826;
    
    }

}
footer {

    width: 100vw;
    overflow-y: hidden;
    color: #FFF;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;

}

.copyright {

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;

}

.copyright p {

    font-size: 9pt

}

.copyright img {

    height: 80px;

}

.address {

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    max-height: 20vh;
    margin-bottom: 15px;
    text-align: center;

}

.address ul {

    display: flex;
    list-style: none;

}

.address li {

    padding: 5px;

}

.address li a {

    cursor: pointer;
    text-decoration: none;
    color: #FFF;

}

.socialMedias {

    height: 20vh;
    display: flex;
    align-items: flex-end;
    padding-right: 25px;

}

.socialMedias img {

    height: 38px;
    padding: 0px 4px;

}

.socialMedias a {

    cursor: pointer;

}

@media screen and (max-width: 768px) {

    footer {

        flex-direction: column;
        padding-bottom: 0;


    }

    .copyright {

        width: 90vw;
        height: auto;
        align-items: center;
        margin: 0px;

    }

    .copyright img {

        height: 110px;

    }

    .address{

        width: 90vw;
        height: auto;
        text-align: justify;
        margin: 0px;
        /* max-height: none; */

    }

    .address ul {

        width: 90vw;
        justify-content: space-between;
        padding: 0;

    }

    .address li {

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9pt;

    }

    .address p {

        font-size: 9pt;

    }

    .socialMedias {

        width: 100vw;
        height: 10vh;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: 50px;

    }

}
.title{

    display: flex;
    align-items: center;

}

.title h1 {

    font-size: 22pt;

}

.title img {

    height: 35px;
    animation: flying 1.44s linear;

}


.Aurea {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 85px;

}


.Aurea h1 {

    color: #DBB826;

}


.Aurea img {

    height: 30px;
    margin-left: 5px;

}


.Aurea img:hover {

    animation: fly 1.8s linear;

}

@keyframes fly {

    0% {

        transform: translateY(0px);

    }

    100% {

        transform: translateY(-70px);
        opacity: 0;

    }

}




@keyframes flying {

    0%{

        transform: 
            translateX(0px)
            /* translateY(0px) */
            /* rotate(0deg); */

    }

    50%{

        transform: 
            translateX(40px);
            /* translateY(-10px) */
            /* rotate(-180deg); */
        opacity: .3;

    }

    100%{

        transform: 
            translateX(0px);
            /* translateY(0px) */
            /* rotate(-180deg); */
        opacity: 1;

    }

}

.title img:hover {

    animation: takeOff 2s linear;

}

@keyframes takeOff {

    0%{

        transform: 
            translateY(0px)
            rotate(-90deg);

    }

    100%{

        transform: 
            translateY(-90px)
            rotate(-90deg);

        opacity: 0;

    }

}
main {

    width: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;

}

.initialInfos {

    margin-top: 80px;

}

.imgWhoWeAre{

    /* width: 600px; */
    /* height: 280px; */
    border-radius: 10px;

}

.initialText{

    width: 40%;
    text-align: justify;
    margin-left: 40px;
    text-indent: 2em;
}

.initialText a {

    color: #DBB826;
    cursor: pointer;
    padding: 0px 3px;

}

.missionValuesEyesight {

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.box {

    width: 300px;
    height: 430px;
    /* max-height: 400px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #cecece;
    padding-bottom: 20px;
    margin-top: 30px;
    font-family: 'Montserrat', sans-serif;

    /* transition: all 0.2s ease; */
    transition: all .75s ease-out;
    transform-style: preserve-3d;

    perspective: 1000px;

}

.box h3 {

    font-size: 25px;

}

.box img {

    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid #DBB826;
    padding: 3px;

}

.box a {

    cursor: pointer;

}

.box b {

    color: #DBB826;

}

.textBox {

    width: 80%;
    background-color: #2b2b2b;
    /* background-color: #FFF; */
    border-radius: 10px;
    /* border: 1px solid #DBB826; */
    box-shadow: 0px 0px 7px #DBB826 ;
    margin-top: 15px;
    padding: 5px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all .75s ease-out;

}

.textBox p {

    text-align: center;
    color: white;

}

.tableAurea {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-family: 'Roboto', sans-serif;

}

.tableAurea tr {

    border-left: 2px solid #DBB826;
    padding-left: 5px;

}

.tableAurea b {

    color: #DBB826;
    margin-right: 2px;

}

#sectionWhoWeAre{

    width: 100%;
    margin: 150px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#sectionReports {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ourReports {

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

}

.ourReports h2 {

    margin-bottom: 8vh;

}

.rowOurReports {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
    flex-direction: column;

}

.rowOurReports figure {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.rowOurReports figcaption {

    text-indent: 2em;
    font-size: 11pt;
    text-align: justify;

}

.rowOurReports figcaption::before {

    content: "❝";
    color: #DBB826;
    font-size: 20pt;
    padding: 0px 3px;

}

.rowOurReports figcaption::after {

    content: "❞";
    color: #DBB826;
    font-size: 20pt;
    padding: 0px 3px;

}

.rowOurReports img {

    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 4px solid #DBB826;

}

.marginLeftTextRepors {

    margin-left: 30px;

}

.marginRightTextRepors {

    margin-right: 30px;

}


@media screen and (max-width: 768px) {

    main {

        flex-direction: column;

    }

    .imgWhoWeAre {

        width: 90vw;
        height: auto;

    }

    .initialText{

        width: 90vw;
        margin-left: 0;


    }

    .missionValuesEyesight {

        flex-direction: column;

    }

    .box {
        width: 90vw;
    }

    .box p {

        text-align: justify;
    
    }

    #sectionReports {

        flex-direction: column;

    }

    .ourReports {

        flex-direction: column;
        width: 90%;

    }

    .ourReports h2 {

        font-size: 14pt;
    
    }

    .rowOurReports {

        padding: 0px 15px;

    }

    .rowOurReports figure {

        flex-direction: column;

    }

    .rowOurReports img {

        margin-bottom: 3vh;

    }

    .inverts figure {

        flex-direction: column-reverse;

    }

    .rowOurReports iframe {

        width: 100vw;
    
    }

    .marginLeftTextRepors {

        margin-left: 0px;
    
    }
    
    .marginRightTextRepors {
    
        margin-right: 0px;
    
    }

    .testtttttt {

        flex-direction: column-reverse;

    }

}
.App {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

#contactMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}

#contactMain h1 {
    color: #DBB826;
}

form {
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

form input {
    padding: 15px;
    border: none;
    color: #FFF;
    border-radius: 5px;
    background-color: black;
    margin-top: 20px;
}

fieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    margin: 64px 0px 0px 0px;
    padding: 0;
    min-inline-size: auto;
    border: 0;
}

fieldset legend h2 {
    font-size: 24px;
}

fieldset form legend span {
    font-size: 14px;
    font-weight: normal;
    color: #DBB826;
}

fieldset .inputboxes {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

fieldset .field-group1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

fieldset .field-group {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

fieldset .field {
    display: flex;
    width: 45%;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
}

fieldset input {
    width: 100%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;
}

fieldset .selects {

    width: 105%;

}

fieldset .selects select {

    width: 110%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;

}

fieldset .selects select option {
    color: #DBB826;
}

fieldset .messageInput {
    width: 60%;
    font-family: 'Montserrat', sans-serif;
}

fieldset #message {
    height: 150px;
    width: 103%;
    background-color: #000;
    border-radius: 5px;
    color: #DBB826;
    padding: 15px;
}

.whatsappContact {
    display: flex;
    align-items: center;
    margin: 80px 0px;
}

.whatsappContact img {
    height: 45px;
    padding-left: 20px;
}

.whatsappContact a {
    cursor: pointer;
}

.sendButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 10px;
    margin-top: 25px;
    width: 130px;
    height: 40px;
}

.sendButton a {
    font-size: 13pt;
    color: #DBB826;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    form {
        width: 90vw;
    }
    fieldset {
        align-items: center;
    }
    fieldset .inputboxes {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    fieldset .field-group {
        flex-direction: column;
        align-items: flex-start;
    }
    fieldset .field-group {
        width: 95%;
    }
    fieldset .field-group1 {
        flex-direction: column;
        align-items: flex-start;
    }
    fieldset .field-group1 {
        width: 95%;
    }
    fieldset .field {
        width: 88%;
    }
    fieldset input {
        width: 95%;
    }
    fieldset .selects {
        width: 100%;
    }
    fieldset .messageInput {
        display: flex;
        align-items: center;
        width: 88%;
    }
}

@media only screen and (max-width: 600px) {

    fieldset .selects select {

        width: 112%;

    }

}
#InfoCourses {

    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;

    font-family: 'Montserrat', sans-serif;

}

#guiJogadorCaro {

    width: 100vw;
    margin-top: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.courseIntro {
    
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.courseImage img {

    width: 500px;
    height: 300px;
    border-radius: 50%;

}

.courseName h1 {

    font-size: 25pt;
    color: #DBB826;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    margin-top: 1px;
    font-family: 'Montserrat', sans-serif;

}

.coursesSlideProjects {

    width: 100vw;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

}

.coursesSlideProjects h2 {

    margin-bottom: 50px;

}

.arrows{

    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

}

.arrows img {

    height: 55px;
    width: 55px;

}

.arrow-left {

    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

}

.arrow-right {

    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

}

.projectsCoursesDiv {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    width: 100%;

}


.projectsCoursesDiv img {

    /* width: 80%; */
    /* height: 10%; */

}

.projectsCoursesDiv h3 {

    font-size: 11pt;
    width: 80%;
    /* margin-top: 50px; */
    text-align: center;

}

.projectsCoursesDiv p {

    width: 80%;
    text-indent: 2em;

}

.imgProjects {

    height: auto;
    max-height: 75%;
    width: 80%;

}

#courseServices {

    width: 100vw;
    max-width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10vw;

}

#courseServices h1 {

    margin-bottom: 3vw;

}

.courseServicesDiv {

    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.boxServicesCourse {

    border-radius: 30px;
    margin: 10px 0px;
    width: 60%;
    text-align: justify;

}

.boxServicesCourse h3 {

    color: #DBB826;
    border-radius: 3px;
    padding: 5px;

}

.boxServicesCourse p {

    text-indent: 2em;

}

.boxServicesCourse a {

    color: #DBB826;

}

#Gom3sCamisa10 {

    margin: 10vw 0px;

}

.slide-v img {

    width: 100px;

}

@media screen and (max-width: 768px) {

    .courseIntro {

        flex-direction: column;
        justify-content: center;
        
    
    }

    .courseName h1 {

        margin-left: 0px;
        margin-top: 30px;
        border-radius: 3px;

    }

    .courseImage img {

        width: auto;

    }

    #courseServices h1 {

        font-size: 15pt;

    }

    .boxServicesCourse {

        width: 95%;

    }

    .boxServicesCourse h3 {

        font-size: 13pt;

    }

    .boxServicesCourse p {

        font-size: 10pt;

    }

    .coursesSlideProjects h2 {

        font-size: 15pt;

    }

    .arrows {

        width: 90vw;

    }

    .arrows img{

        width: 40px;
        height: 40px;

    }

}
#Blog {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: black; */
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;

}

#mainBlog {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    margin-bottom: 10vh;

}

#mainBlog h1 {

    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;

}

#mainBlog h3 {

    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;

}

.postDiv {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Montserrat', 'sans-serif';
    margin-top: 10vh;
    /* background-color: rebeccapurple; */

}

.postDiv img{

    max-width: 40%;
    border-radius: 10px;

}

.postDiv h3 {

    padding: 15px;
    color: white;
    font-size: 22pt;
    position: relative;
    background-color: #DBB826;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 25px;
    border-top-left-radius:     25px;
    border-top-right-radius:    25px;

}

.postDiv h5{

    font-size: 13pt;
    max-width: 70%;

}

.postDiv p{

    width: 80%;
    color: black;
    text-align: justify;
    text-indent: 2em;
    white-space: pre-line;

}

.postAuthor {

    width: 80%;
    text-align: right;

}

.postAuthor span{

    color: white;
    padding: 5px;
    font-size: 10pt;
    border-radius: 5px;
    background-color: #DBB826;

}

.paragraphPost {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;

}

.paragraphPost p {

    width: 100%;
    text-align: justify;

}

.caminhoAviao{

    border-radius: 150px;
    width: 100%;
    height: 90%;
    animation: anticlockwise 8s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;

}

.caminhoAviao img {

    width: 30px;
    position: absolute;
    top: 0px;

}

.warning {

    position: absolute;
    top: 50%;

}

.warning p {

    font-family: 'Montserrat', sans-serif;
    font-size: 15pt;
    color: #DBB826;

}

.testeee {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 300px;
    height: 300px;

}


.warning p::after {

    animation: testt 5s infinite;
    content: '';

}

.sendToLinkedin {

    width: 100vw;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
    text-align: center;

}

.sendToLinkedin p {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;

}

.sendToLinkedin a {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;
    font-weight:bold;
    cursor: pointer;
    text-decoration: none;

}

@keyframes anticlockwise {

    0% {

        transform: rotate(-360deg);

    }
    100% {

        transform: rotate(0deg);

    }

}

@keyframes testt {

    0% {

        content: '.';

    }
    50% {

        content: '..';

    }
    75% {

        content: '...';

    }
    100% {

        content: '';

    }

}

@media screen and (max-width: 768px) {

    #mainBlog h1 {

        font-size: 14pt;

    }

    .postDiv h3 {

        width: 60%;
        padding: 10px;
        font-size: 18pt;
        text-align: center;

    }

    .postDiv h5 {

        font-size: 11pt;
        text-indent: 2em;
        text-align: justify;

    }
    
    .postDiv img{

    max-width: 80%;

}
    
}

#BlogAdmin {

    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

#mainBlogAdmin {

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

#mainBlogAdmin fieldset {

    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

#mainBlogAdmin fieldset input{

    color: #FFF;

}

#mainBlogAdmin fieldset textarea {

    width: 103%;
    height: 500px;
    color: #FFF;
    padding: 10px;
    border-radius: 5px;
    background-color: black;
    margin-bottom: 5vh;

}

.sendButtonBlog {

    cursor: pointer;
    padding: 15px;
    background-color: #DBB826;
    border-radius: 5px;
    font-size: 11pt;
    margin-bottom: 10vh;

}

.defaultSectionAdmin {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.defaultSectionAdmin select {

    width: 50%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;

}

#BlogLogin {

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #000;

}

.loginForms {

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', 'sans-serif';

}

.loginForms input {

    padding: .5rem 2rem .5rem 1rem;
    margin: .3rem 0;
    font-size: 17px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #141414;
    color: #fff;

}

.loginForms input::-webkit-input-placeholder {

    color: rgb(114, 114, 114);

}

.loginForms input:-ms-input-placeholder {

    color: rgb(114, 114, 114);

}

.loginForms input::-ms-input-placeholder {

    color: rgb(114, 114, 114);

}

.loginForms input::placeholder {

    color: rgb(114, 114, 114);

}

.loginForms a {

    text-align: center;
    background-color: #dbb826;
    cursor: pointer;
    font-size: 18px;
    margin: 1rem 0;
    border-radius: 4px;
    width: 40%;
    padding: .4rem;
    font-weight: 500;

}

.loginForms a:hover {

    opacity: .9;

}

.loginForms img {

    width: 250px;

}

@media screen and (max-width: 768px) {

    #mainBlogAdmin {

        width: 80%;

    }

}
