@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

#InfoCourses {

    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;

    font-family: 'Montserrat', sans-serif;

}

#guiJogadorCaro {

    width: 100vw;
    margin-top: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.courseIntro {
    
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.courseImage img {

    width: 500px;
    height: 300px;
    border-radius: 50%;

}

.courseName h1 {

    font-size: 25pt;
    color: #DBB826;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    margin-top: 1px;
    font-family: 'Montserrat', sans-serif;

}

.coursesSlideProjects {

    width: 100vw;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

}

.coursesSlideProjects h2 {

    margin-bottom: 50px;

}

.arrows{

    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

}

.arrows img {

    height: 55px;
    width: 55px;

}

.arrow-left {

    cursor: pointer;
    user-select: none;

}

.arrow-right {

    cursor: pointer;
    user-select: none;

}

.projectsCoursesDiv {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    width: 100%;

}


.projectsCoursesDiv img {

    /* width: 80%; */
    /* height: 10%; */

}

.projectsCoursesDiv h3 {

    font-size: 11pt;
    width: 80%;
    /* margin-top: 50px; */
    text-align: center;

}

.projectsCoursesDiv p {

    width: 80%;
    text-indent: 2em;

}

.imgProjects {

    height: auto;
    max-height: 75%;
    width: 80%;

}

#courseServices {

    width: 100vw;
    max-width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10vw;

}

#courseServices h1 {

    margin-bottom: 3vw;

}

.courseServicesDiv {

    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.boxServicesCourse {

    border-radius: 30px;
    margin: 10px 0px;
    width: 60%;
    text-align: justify;

}

.boxServicesCourse h3 {

    color: #DBB826;
    border-radius: 3px;
    padding: 5px;

}

.boxServicesCourse p {

    text-indent: 2em;

}

.boxServicesCourse a {

    color: #DBB826;

}

#Gom3sCamisa10 {

    margin: 10vw 0px;

}

.slide-v img {

    width: 100px;

}

@media screen and (max-width: 768px) {

    .courseIntro {

        flex-direction: column;
        justify-content: center;
        
    
    }

    .courseName h1 {

        margin-left: 0px;
        margin-top: 30px;
        border-radius: 3px;

    }

    .courseImage img {

        width: auto;

    }

    #courseServices h1 {

        font-size: 15pt;

    }

    .boxServicesCourse {

        width: 95%;

    }

    .boxServicesCourse h3 {

        font-size: 13pt;

    }

    .boxServicesCourse p {

        font-size: 10pt;

    }

    .coursesSlideProjects h2 {

        font-size: 15pt;

    }

    .arrows {

        width: 90vw;

    }

    .arrows img{

        width: 40px;
        height: 40px;

    }

}