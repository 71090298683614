@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

#BlogAdmin {

    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

#mainBlogAdmin {

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

#mainBlogAdmin fieldset {

    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

#mainBlogAdmin fieldset input{

    color: #FFF;

}

#mainBlogAdmin fieldset textarea {

    width: 103%;
    height: 500px;
    color: #FFF;
    padding: 10px;
    border-radius: 5px;
    background-color: black;
    margin-bottom: 5vh;

}

.sendButtonBlog {

    cursor: pointer;
    padding: 15px;
    background-color: #DBB826;
    border-radius: 5px;
    font-size: 11pt;
    margin-bottom: 10vh;

}

.defaultSectionAdmin {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.defaultSectionAdmin select {

    width: 50%;
    border-radius: 5px;
    background-color: black;
    border: none;
    margin: 3px 0px 50px 0px;
    padding: 16px 24px;
    font-size: 14px;
    color: #DBB826;

}

#BlogLogin {

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #000;

}

.loginForms {

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', 'sans-serif';

}

.loginForms input {

    padding: .5rem 2rem .5rem 1rem;
    margin: .3rem 0;
    font-size: 17px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #141414;
    color: #fff;

}

.loginForms input::placeholder {

    color: rgb(114, 114, 114);

}

.loginForms a {

    text-align: center;
    background-color: #dbb826;
    cursor: pointer;
    font-size: 18px;
    margin: 1rem 0;
    border-radius: 4px;
    width: 40%;
    padding: .4rem;
    font-weight: 500;

}

.loginForms a:hover {

    opacity: .9;

}

.loginForms img {

    width: 250px;

}

@media screen and (max-width: 768px) {

    #mainBlogAdmin {

        width: 80%;

    }

}