
header {

    height: 10vh;
    width: 100vw;
    background-color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

}

.logo {

    padding-left: 20px;
    display: flex;
    align-items: center;

}

.logo img {
    height: 7vh;
}

.logo a {
    cursor: pointer;
}

.menu {

    width: 50vw;
    display: flex;
    flex-direction: row;

}

.menu ul {

    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.menu li {

    list-style: none;
    color: #DBB826;
    padding: 5px;
    font-size: 13pt;
    cursor: pointer;

}

.menu a {

    text-decoration: none;
    color: #FFF;

}

.menu a:hover {

    color: #DBB826;

}

.menu-mobile {

    display: none;

}

.sandwich {

    display: none;

}

.sandwich input {

    opacity: 0;

}


@media screen and (max-width: 768px) {

    .menu {
        display: none;
    }

    .sandwich {

        display: block;
        display: flex;
        align-items: center;
        padding-right: 20px;

    }

    .sandwich label {

        cursor: pointer;
        position: relative;
        display: block;
        height: 5px;
        width: 50px;

    }

    .sandwich span {

        position: absolute;
        height: 3px;
        width: 30px;
        background: #dbb826;
        transition: .5s ease-in-out;

    }

    .sandwich span:nth-child(1) {

        position: absolute;
        top: -8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    .sandwich span:nth-child(3) {

        position: absolute;
        top: 8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    #checkbox:checked + label span:nth-child(1) {

        transform: rotate(-225deg);
        top: 0px;

    }

    #checkbox:checked + label span:nth-child(2) {

        opacity: 0;

    }

    #checkbox:checked + label span:nth-child(3) {

        transform: rotate(225deg);
        top: 0px;

    }

    .menu-mobile {

        width: 100vw;
        height: 60px;
        display: none;
        align-items: center;
        justify-content: center;
        background-color: #000;

    }

    .menu-mobile ul {

        margin: 0;
        padding: 0;
        width: 90%;
        display: flex;
        justify-content: space-between;
        list-style: none;
        font-size: 9pt;
        color: #FFF;

    }

    .menu-mobile li {

        cursor: pointer;
        color: #FFF;

    }

    .menu-mobile a {

        text-decoration: none;
        color: #FFF;

    }

    .menu-mobile a:hover {

        color: #DBB826;
    
    }

}