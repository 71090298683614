@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {

    scroll-behavior: smooth;

}

:root {

    overflow-x: hidden;

}

::-webkit-scrollbar {

    background: #FFF;

}

::-webkit-scrollbar-thumb { 

    background-color: #000;
    
}

.Home {

    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

#mainHome {

    width: 100vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-image: url("../../imgs/backComp.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */

}

#mainHome img {

    width: 1000px;

}

#presentationDiv {

    height: 100%;
    width: 100%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    
    
}

#presentationDiv img {

    height: 400px;
    width: 400px;
    margin-left: 10vw;

}

.phraseIntro {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;

}

.phraseIntro h1 {

    font-size: 25pt;
    color: #DBB826;
    background-color: black;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    margin-top: 1px;
    font-family: 'Montserrat', sans-serif;

}

.descIntro {

    width: 100%;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;

}

.waveImage {

    height: 100%;
    width: 300px;
}

#services {

    height: 90vh;
    width: 100vw;
    margin-top: 30vh;
    margin-bottom: 30vh;

}

#services h1 {

    font-size: 20pt;
    font-family: 'Montserrat', sans-serif;

}

.flexCol {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.sectionHome{

    margin-top: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.matrixServices {

    width: 95%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.rowMatrixServices {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boxServices {

    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

}

.boxServices a {

    cursor: pointer;

}

.boxServices img {

    height: 220px;
    transition: all 1s linear;
    margin-bottom: 20px;

}

.servicesBack {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 1s linear;

}

.servicesBack a {

    transition: all 2s linear;
    background-color: #DBB826;
    border-radius: 3px;
    padding: 5px;
    margin: 3px;
    opacity: 1;
    text-decoration: none;

}

.servicesBack a:hover {

    transition: all .2s linear;
    box-shadow: 0px 2px 8px #dbb726d2;

}

.servicesBack b {

    transition: all 2s linear;
    background-color: #DBB826;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin: 3px;
    opacity: 1;

}

.marginTopServicesBack {

    margin-top: 10px;

}

@keyframes teste {

    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-10px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }

}

@keyframes jumpAndDeformation {

    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-3px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }


}

#ourProjects {

    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 250px;

}

#ourProjects h1 {

    font-size: 24pt;

}

.slideProjects {

    width: 100vw;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.imgProjects {

    height: 500px;
    width: auto;
    border-radius: 10px;

}

.slide {

    opacity: 0;
    transition-duration: 1s ease;

}
  
.slide.active {

    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);

}

#feedbacks {

    width: 100vw;

}

.aboutUs {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15vh;
    margin-bottom: 30vh;
    /* background-color: #DBB826; */

}

.whyUs {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    text-indent: 2em;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 10px;

}

.parceiros {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 10px;
    width: 80%;
    margin-top: 30px;
}

.parceirosLogos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.parceirosLogos a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.parceirosLogos img {
    width: 100%;
    height: 50%;
}

.accountants {

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15vh;

}

.counter {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}

.counter img {

    width: 100px;
    height: 100px;
    margin-bottom: 15px;

}

.counter strong {

    color: #DBB826;
    font-size: 20pt;

}


@media screen and (max-width: 768px) {

    #mainHome {

        flex-direction: column;
        height: auto;

    }

    #mainHome h1 {

        font-size: 18pt;

    }

    #presentationDiv{

        flex-direction: column;
        justify-content: center;
        text-align: justify;
        padding: 0px 30px;

    }

    #presentationDiv img{

        margin: 0;

    }

    #services {

        height: auto;
        margin-top: 15vh;
        margin-bottom: 0;

    }

    .descIntro {

        width: 90%;

    }

    /* .descIntro p {

        font-size: 14px;

    } */

    .rowMatrixServices {

        flex-direction: column;

    }

    .boxServices {

        margin-top: 20px;

    }

    .marginTop {

        margin-top: 80px;

    }

    #ourProjects {

        height: 70vh;
        margin-top: 100px;

    }

    .imgProjects {

        height: 300px;
        width: 90vw;

    }

    .accountants {

        flex-direction: column;

    }

    .counter {

        margin: 15px 0px;

    }

    .aboutUs {

        margin-top: 8vh;
        margin-bottom: 8vh;

    }
    .accountants {

        margin-top: 10vh;

    }

    .parceirosLogos {
        flex-direction: column;
    }

}

@media only screen and (max-width: 645px) {

    .descIntro {

        width: 75%;

    }

    .descIntro p {

      font-size: 10pt;

    }

    #services h1 {

        font-size: 17pt;

    }

    .whyUs h3 {

        font-size: 15pt;
        text-align: center;

    }

}

.whatsappButton {

    position: fixed;
    z-index: 9;
    bottom: 20px;
    left: 25px;

}

.whatsappButton img {

    width: 80px;

}