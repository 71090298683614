@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

main {

    width: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;

}

.initialInfos {

    margin-top: 80px;

}

.imgWhoWeAre{

    /* width: 600px; */
    /* height: 280px; */
    border-radius: 10px;

}

.initialText{

    width: 40%;
    text-align: justify;
    margin-left: 40px;
    text-indent: 2em;
}

.initialText a {

    color: #DBB826;
    cursor: pointer;
    padding: 0px 3px;

}

.missionValuesEyesight {

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.box {

    width: 300px;
    height: 430px;
    /* max-height: 400px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #cecece;
    padding-bottom: 20px;
    margin-top: 30px;
    font-family: 'Montserrat', sans-serif;

    /* transition: all 0.2s ease; */
    transition: all .75s ease-out;
    transform-style: preserve-3d;

    perspective: 1000px;

}

.box h3 {

    font-size: 25px;

}

.box img {

    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid #DBB826;
    padding: 3px;

}

.box a {

    cursor: pointer;

}

.box b {

    color: #DBB826;

}

.textBox {

    width: 80%;
    background-color: #2b2b2b;
    /* background-color: #FFF; */
    border-radius: 10px;
    /* border: 1px solid #DBB826; */
    box-shadow: 0px 0px 7px #DBB826 ;
    margin-top: 15px;
    padding: 5px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all .75s ease-out;

}

.textBox p {

    text-align: center;
    color: white;

}

.tableAurea {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-family: 'Roboto', sans-serif;

}

.tableAurea tr {

    border-left: 2px solid #DBB826;
    padding-left: 5px;

}

.tableAurea b {

    color: #DBB826;
    margin-right: 2px;

}

#sectionWhoWeAre{

    width: 100%;
    margin: 150px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#sectionReports {

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ourReports {

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

}

.ourReports h2 {

    margin-bottom: 8vh;

}

.rowOurReports {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
    flex-direction: column;

}

.rowOurReports figure {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.rowOurReports figcaption {

    text-indent: 2em;
    font-size: 11pt;
    text-align: justify;

}

.rowOurReports figcaption::before {

    content: "❝";
    color: #DBB826;
    font-size: 20pt;
    padding: 0px 3px;

}

.rowOurReports figcaption::after {

    content: "❞";
    color: #DBB826;
    font-size: 20pt;
    padding: 0px 3px;

}

.rowOurReports img {

    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 4px solid #DBB826;

}

.marginLeftTextRepors {

    margin-left: 30px;

}

.marginRightTextRepors {

    margin-right: 30px;

}


@media screen and (max-width: 768px) {

    main {

        flex-direction: column;

    }

    .imgWhoWeAre {

        width: 90vw;
        height: auto;

    }

    .initialText{

        width: 90vw;
        margin-left: 0;


    }

    .missionValuesEyesight {

        flex-direction: column;

    }

    .box {
        width: 90vw;
    }

    .box p {

        text-align: justify;
    
    }

    #sectionReports {

        flex-direction: column;

    }

    .ourReports {

        flex-direction: column;
        width: 90%;

    }

    .ourReports h2 {

        font-size: 14pt;
    
    }

    .rowOurReports {

        padding: 0px 15px;

    }

    .rowOurReports figure {

        flex-direction: column;

    }

    .rowOurReports img {

        margin-bottom: 3vh;

    }

    .inverts figure {

        flex-direction: column-reverse;

    }

    .rowOurReports iframe {

        width: 100vw;
    
    }

    .marginLeftTextRepors {

        margin-left: 0px;
    
    }
    
    .marginRightTextRepors {
    
        margin-right: 0px;
    
    }

    .testtttttt {

        flex-direction: column-reverse;

    }

}